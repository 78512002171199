.form {
  max-width: 40rem;
  margin: 2rem auto;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  color: #fff;
  backdrop-filter: blur(4px);
}

.form label,
.form input,
.form textarea .form select {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}

.form input,
.form textarea .form select {
  font: inherit;
  padding: 0.25rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #ffffff;
}

/* Adding specific styles for textarea */
.form textarea {
  background-color: rgb(48, 66, 67);
  color: rgb(252, 250, 250);
  width: 500px;
}

/* Adding specific styles for input */
.form input {
  background-color: rgb(48, 66, 67);
  color: rgb(252, 250, 250);
  width: 300px;
}

.form select {
  background-color: rgb(48, 66, 67);
  color: rgb(252, 250, 250);
}

.form label {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.network {
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 0.2rem;
  width: 50%;
}

.network input[type="radio"] {
  margin-right: 5rem;
  /* Space between radio button and label */
}

.network label {
  text-transform: lowercase;
  margin: 0;
}

.actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1rem;
}

.actions button {
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  background-color: #000;
  color: white;
  border: none;
  transition:
    background-color 0.3s,
    box-shadow 0.3s,
    transform 0.3s;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.5);
}

.actions button[type="button"] {
  background-color: transparent;
  color: var(#ccc9c6);
}

.actions button[type="reset"] {
  background-color: transparent;
  color: var(#ccc9c6);
}

.actions button:hover {
  background-color: var(#fae1af);
}

.actions button[type="button"]:hover {
  background-color: var(#31302e);
}

.actions button[type="reset"]:hover {
  background-color: var(#31302e);
}

@media (max-width: 768px) {
  .form {
    padding: 1rem;
    align-items: center;
  }

  .form label,
  .form input,
  .form textarea .form select {
    display: block;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .form textarea {
    background-color: rgb(48, 66, 67);
    color: rgb(252, 250, 250);
    width: 300px;
  }

  .actions {
    /*flex-direction: column;*/
    align-items: center;
    gap: 0.5rem;
  }
}

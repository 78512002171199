.form {
  max-width: 40rem;
  margin: 2rem auto;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  color: #fff;
  backdrop-filter: blur(10px);
}

.form h1 {
  text-align: center;
  font-size: 1.9rem;
  color: grey;
  margin-bottom: 1rem;
  position: relative;
  font-weight: bold;
}

.form h2 {
  text-align: center;
  font-size: 1.2rem;
  color: grey;
  margin-bottom: 1rem;
  position: relative;
  font-weight: bold;
}
.form label,
.form input,
.form textarea {
  display: block;
  width: 100%;
  color: grey;
  margin-bottom: 0.5rem;
}

.form input,
.form textarea {
  font: inherit;
  padding: 0.25rem;
}

.form input {
  font: inherit;
  font-size: 1.2rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: rgb(48, 66, 67);
  color: rgb(252, 250, 250);
}

.form label {
  text-transform: uppercase;
  font-weight: bold;
  color: grey;
}

.actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
}
.actions a {
  color: grey;
}
.actions a:hover {
  color: rgb(220, 217, 217);
}
@media (max-width: 768px) {
  .form {
    padding: 1rem;
  }

  .actions {
    align-items: center;
    gap: 0.5rem;
  }
}

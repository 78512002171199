@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

:root {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.77);
  background-color: #1a1a1a;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

dialog {
  border: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%; /* Adjust as necessary */
  max-width: 450px; /* Optional, for responsive width */
  z-index: 1000; /* Ensures it's above other content */
}

/* This is for the backdrop styling */
::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

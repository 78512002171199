.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(150, 150, 150, 0.8);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.container p {
  font-size: 1.5rem;
  color: #9bafaf;
}

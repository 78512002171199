.button {
  border-radius: 6px;
  box-sizing: border-box;
  color: #ffffff;
  display: block;
  height: 40px;
  padding: 4px;
  position: initial;
  text-decoration: none;
  min-width: 5.3em;
  z-index: 2;
  background-color: black;
  box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.438);
  transition: box-shadow 0.25s;
}

.button:hover {
  background-color: #1e1e1e; /* Lighter on hover */
  box-shadow: 0 4px 20px rgba(255, 255, 255, 0.438); /* More pronounced shadow on hover */
}

.button:active {
  background-color: #2a2a2a; /* Darker when clicked */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); /* Flatter shadow when clicked */
}
